// colors
$cl-white: #fff;
$cl-black: #231F20;
$cl-gray-1: #58595B;
$cl-gray-2: #B4B4B4;
$cl-gray-3: #DEDEDE;
$cl-gray-4: #EFEFEF;

$cl-blue-1: #013B82;
$cl-blue-2: #00539B; 
$cl-blue-3: #64BDE1;
$cl-marine: #006684;

$cl-error-color: #D2000D;
$cl-error-bg: #FFE9EA;

//Fonts
$font-oswald: 'Oswald', sans-serif;
$font-merri: 'Merriweather', serif;


$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-26: 26px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-40: 40px;

.u-wd100p {
	width: 100%;
}

.u-of-hidden {
  overflow: hidden;
}

.u-of-hidden-x {
  overflow-x: hidden;
}

.u-of-auto-x {
  overflow-x: auto;
}

.u-of-auto-y {
  overflow-y: auto;
}

.u-ta-left {
	text-align: left;
}
.u-ta-center {
	text-align: center;
}
.u-ta-right {
	text-align: right;
}

.u-upc {
	text-transform: uppercase;
}

// fonts
.u-oswald-l {
	font-family: $font-oswald;
	font-weight: 300;
}
.u-oswald-r {
	font-family: $font-oswald;
	font-weight: 400;
}
.u-oswald-m {
	font-family: $font-oswald;
	font-weight: 500;
}

.u-merri-l {
	font-family: $font-merri;
	font-weight: 300;
}

.u-merri-r {
	font-family: $font-merri;
	font-weight: 400;
}
.u-merri-ri {
	font-family: $font-merri;
	font-weight: 400;
	font-style: italic;
}


.u-cl-white {
	color: $cl-white;
}

.u-cl-black {
	color: $cl-black;
}

.u-cl-blue-1 {
	color: $cl-blue-1;
}
.u-cl-blue-2 {
	color: $cl-blue-2;
}
.u-cl-blue-3 {
	color: $cl-blue-3;
}

.u-cl-gray-1 {
	color: $cl-gray-1;
}
.u-cl-gray-2 {
	color: $cl-gray-2;
}
.u-cl-gray-3 {
	color: $cl-gray-3;
}

.u-bg-blue-1 {
	background-color: $cl-blue-1;
}

strong, b {
	font-family: $font-oswald;
	font-weight: 500;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 10px;
	font-family: $font-merri;
	scroll-behavior: smooth;

	&.is-open {
		overflow: hidden;
		margin-right: calc(-1 * (100vw - 100%));
	}

}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	font-size: 1rem;
	line-height: 1;
	color: $cl-black;

	// &.is-open {
	// 	overflow: hidden;
	// }
}

a {
	text-decoration: none;
	cursor: pointer;
}

button, a {
	transition: all .3s ease;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-oswald;
	font-weight: 500;
}



.img-with-text {

	align-items: flex-end;

	@media screen and (min-width: $screen-lg) {
		align-items: center;
	}
}

:root {
	--app-height: 100%;
}

 