@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icons/icomoon.eot?d89512');
  src:  url('../fonts/icons/icomoon.eot?d89512#iefix') format('embedded-opentype'),
    url('../fonts/icons/icomoon.ttf?d89512') format('truetype'),
    url('../fonts/icons/icomoon.woff?d89512') format('woff'),
    url('../fonts/icons/icomoon.svg?d89512#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* font-size: 2rem; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-up:before {
  content: "\e900";
}
.icon-menu:before {
  content: "\e901";
}
.icon-search:before {
  content: "\e902";
}
.icon-chevron-down:before {
  content: "\e903";
}
.icon-arrow-to-go:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-chevron-left:before {
  content: "\e906";
}
.icon-chevron-right:before {
  content: "\e907";
}
.icon-location:before {
  content: "\e908";
}
.icon-external:before {
  content: "\e909";
}
.icon-arrow-down:before {
  content: "\e90a";
}
.icon-edit:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\e90c";
}
.icon-instagram:before {
  content: "\e90d";
}
.icon-youtube:before {
  content: "\e90e";
}
.icon-pinterest:before {
  content: "\e90f";
}
.icon-arrow-prev:before {
  content: "\e910";
}
.icon-arrow-next:before {
  content: "\e911";
}

.icon-clip:before {
  content: "\e912";
}

.icon-loc-arrow:before {
  content: "\e913";
}