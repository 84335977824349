.title {

  text-transform: uppercase;
  h1 {
    font-size: $font-size-18;
    line-height: 130%;
  }
  h2 {
    font-size: $font-size-18;
    line-height: 130%;
  }

  @media screen and (min-width: $screen-md) {
    h1 {
      font-size: $font-size-26;
    }
    h2 {
      font-size: $font-size-24;
    }
  }

  @media screen and (min-width: $screen-lg) {
    h1 {
      font-size: $font-size-40;
    }
    h2 {
      font-size: $font-size-32;
    }
  }
}