.asort {
  position: relative;

  &-scroller {
    display: flex;
  }

  &-slide {
    width: 24.5rem;
  }

  &-item {
    position: relative;

    &__label {
      z-index: 10;
      font-size: $font-size-16;
      line-height: $font-size-20;
      color: $cl-white;
      position: absolute;
      bottom: 3rem;
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  @media screen and (min-width: $screen-md) {
    &-slide {
      width: 32rem;
    }
  }

  @media screen and (min-width: $screen-lg) {
    &-slide {
      width: 25%;
    }
  }

}