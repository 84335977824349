.breadcrumbs {
  &-box {
    padding-top: 6.4rem;
    padding-bottom: 1.6rem;
  }

  &-list {
    display: flex;
  }

  &-item {
    flex-shrink: 0;
    width: auto;
    position: relative;
    display: none;

    &:nth-last-child(2) {
      display: block;
    }

    &:before{
      content: "\e906";
      font-family: 'icomoon' !important;
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }

    span {
      color: $cl-blue-1;
    }

    a, span {
      font-size: $font-size-12;
    }

    a {
      color: $cl-gray-2;
    }
  }

  @media screen and (min-width: $screen-md) {
    &-box {
      padding-top: 10rem;
      padding-bottom: 2.4rem;
    }

    &-item {
      display: block;

      &:first-child:not(:last-child):before {
        display: none;
      }
    }
  }
}
