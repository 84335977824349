.blog-swiper {
  position: relative;

  &-slide {
    width: 29rem;
  }

  .blog-prev,
  .blog-next {
    position: absolute;
    top: 0;
  }

  .blog-prev {
    left: 0;
  }

  .blog-next {
    right: 0;
  }

  @media screen and ( min-width: $screen-md ) {
    padding-top: 4rem;
    &-slide {
      width: 29rem;
    }
  }

  @media screen and ( min-width: $screen-lg ) {
    &-slide {
      width: 35rem;
    }
  }

  @media screen and ( min-width: $screen-xl ) {
    
    &-slide {
      width: 42rem;
    }
  }
}
.blog-card {
  position: relative;
  border: .1rem solid $cl-gray-3;
  border-radius: .2rem;
  height: 100%;

  &__info {
    padding: 1.4rem 1.6rem;
  }

  &__title {
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 4.4rem;
    text-align: center;
    overflow: hidden;

    a {
      color: $cl-black;
      bottom: 0;
    
      &:after {
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 5;
      }
    }
  }

  @media screen and ( min-width: $screen-md ) {
    &__title {
      margin-bottom: 1.2rem;
    }
  }
}