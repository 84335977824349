.pro-slider {
  &__item {
    margin-bottom: 2rem;

    &-title {
     padding-bottom: 2rem;
      cursor: pointer;

      &:hover {
        color: blue;
      }
    }

    &-image {
      margin-bottom: 2rem;
     display: none;
    }

    &-content {
      margin-bottom: 2rem;
      display: none;
      color: $cl-gray-1;

      p {
        font-family: $font-oswald;
        font-size: $font-size-18;
        line-height: 110%;
        margin-bottom: 2rem;
      }

      blockquote {
       
        font-size: $font-size-14;
        line-height: 2rem;
        margin-top: 5rem;
        font-style: italic;
        margin-bottom: 0;
        max-width: 31rem;
      }
    }

    &.active > &-title {
      color: red;
    }

    &.active > &-content, &.active > &-image {
      display: block;
    }
  }

  @media only screen and (min-width: $screen-md) {
    position: relative;

    &__item {
      display: flex;
      width: 100%;
      margin-bottom: 1rem;

      &.active > &-image {
        opacity: 1;
      }
      &.active > &-content {
         opacity: 1;
      }

      &-image {
        position: absolute;
        width: 40%;
        left: 0;
        bottom: 0;
        margin-bottom: 0;
        display: block;
        transition: all 0.5s ease;
        opacity: 0;
      }

      &-content {
        display: block;
        transition: all 0.5s ease;
        opacity: 0;
      }

      &-title {
        width: 18.5rem;
        flex-shrink: 0;
        margin: 0 auto 5rem;
        padding-bottom: 0;
      }

      &-content {
        position: absolute;
        width: 40%;
        right: 0;
        bottom: 0;
        margin-bottom: 0;

        p {
          margin-bottom: 2rem;
        }
      }

      &:last-child > &-title {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (min-width: $screen-xl) {
    &__item {
      &-content {
         p {
            font-size: 2.4rem
         }
       }
     }
   }
}