.footer {
  background: $cl-black;

  &__logo {
    width: 4rem;
    display: block;
  }

  &__social {
    display: flex;
    justify-content: center;
    margin-bottom: 8rem;
    a {
      color: $cl-blue-3;
    }
  }

  &-navi {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-bottom: 1.6rem;
    }

    li:not(:first-child) {
      width: calc(50% - 1.6rem);
      display: inline-flex;
    }

    a {
      font-size: $font-size-14;
      color: $cl-white;
      white-space: nowrap;

      &:hover {
        color: $cl-blue-3;
      }
    }

    border-bottom: 1px solid $cl-gray-1;
  }

  @media screen and (min-width: $screen-md) {
    &-navi {
      justify-content: space-between;

      li:not(:first-child) {
        width: auto;
      }
    }

    &-social {
      display: flex;
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: $screen-lg) {
    &-navi {
      align-items: flex-end;

      li {
        margin-bottom: 0;
      }

      li:not(:first-child) {
        width: auto;
        margin-left: 3rem;
      }

      a {
        font-size: $font-size-16;
      }
    }
  }

  @media screen and (min-width: $screen-xl) {
    &-navi {
      
    

      a {
        font-size: $font-size-18;
      }
    }
  }
}