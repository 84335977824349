
.ms {
	background-size: cover;
	padding: 1rem 0;
	width: 100%;
	height: 100vh;
	position: relative;

	&-container {
		height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		z-index: 10;
		position: relative;

		h1 {
			text-align: center;
			font-size: 4rem;
			text-transform: uppercase;
			color: $cl-black;
		}
	}

	&-video-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
}

	&-intro {
		max-width: 36rem;
		color: $cl-black;
	}

	@media only screen and (min-width: $screen-md) {
		padding: 12rem 0;

		&-container {
			padding-left: 2.4rem;
			padding-right: 2.4rem;

			h1 {
				font-size: 5rem;
			}
		}
		&-intro {
			max-width: 42rem;
		}
  }

	@media only screen and (min-width: $screen-lg) {
		&-container {
			h1 {
				font-size: 8rem;
			}
		}
	}

	@media only screen and (min-width: $screen-xl) {
		&-container {
			h1 {
				font-size: 11.8rem;
			}
		}
	}
}



.benefits {

	&-title {
		font-size: $font-size-24 * 2;
		color: $cl-gray-3;
	}

	&-content {
		li {
			position: relative;
			font-size: $font-size-14;
			line-height: 135%;
			padding-left: 1rem;

			&:not(:last-child) {
				margin-bottom: 1.6rem;
			}

			&:before {
				content: '';
				width: .3rem;
				height: .3rem;
				border-radius: 50%;
				background: $cl-black;
				display: block;
				position: absolute;
				left: 0;
				top: .8rem;
			}
		}
	}

	@media screen and (min-width: $screen-md) {
		&-title {
			align-self: center;
			text-align: right;
			font-size: 6rem;
		}
	}

	@media screen and (min-width: $screen-lg) {
		&-title {
			align-self: center;
			text-align: right;
			font-size: 11rem;
		}
		&-content {
			li {
				font-size: $font-size-16;

				&:before {
					top: 1rem;
				}
			}
		}
	}
}


.history {
	&-swiper {
		position: relative;
	}

	&-slide {
		width: 27rem;

		&__date {
			position: relative;
			background: url('/assets/img/svg/chevrons.svg') 0 0 no-repeat;
		}
	}

	&-prev, &-next {
		position: absolute;
		top: 0;
		z-index: 50;
	}
 
	&-prev {
		left: 0;
	}

	&-next {
		right: 0;
	}

	@media screen and (min-width: $screen-md) {
		&-slide {
			width: 41rem;
		}
	}
}


.team {
	&-swiper {
		overflow: hidden;
		position: relative;
	}
	&-slide {
		&__title {
			margin-bottom: .6rem;
		}

		&__image {
			overflow: hidden;

			img:nth-child(2) {
				position: absolute;
				object-fit: cover;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: all 0.3s ease;
				z-index: 10;
				opacity: 0;
			}

			&:hover {
				 img:nth-child(2) {
					 opacity: 0;
				 }
			}

			@media screen and (min-width: $screen-lg) {
				img:nth-child(2) { opacity: 1; }
			}
		}
	}

	&-navi {
		padding-bottom: 1rem;
	}

	&__prev, &__next {
		width: auto;
	}
}