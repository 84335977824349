.u-fs14 {
	p {
		font-size: $font-size-14;
		line-height: $font-size-20;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

.u-mq-fs14 {
	p {
		font-size: $font-size-14;
		line-height: $font-size-20;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	@media screen and (min-width: $screen-xl) {
		p {
			font-size: $font-size-18;
			line-height: $font-size-26;
		}
	}
}

.u-mq-fs16 {
	font-size: $font-size-14;
	line-height: $font-size-20;

	&:not(:last-child) {
		margin-bottom: 2rem;
	}

	@media screen and (min-width: $screen-md) {
		font-size: $font-size-16;
		line-height: $font-size-22;
	}
}

.u-mq-fs18 {
	p {
		font-size: $font-size-16;
		line-height: $font-size-20;

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}

		@media screen and (min-width: $screen-xl) {
			font-size: $font-size-18;
			line-height: $font-size-22;
		}
	}
}

.u-mq-fs20 {
	p {
		font-size: $font-size-16;
		line-height: 130%;

		@media screen and (min-width: $screen-md) {
			font-size: $font-size-18;
		}

		@media screen and (min-width: $screen-xl) {
			font-size: $font-size-20;
		}
	}
}

.u-mq-fs24 {
	p {
		font-size: $font-size-18;
		line-height: 130%;

		@media screen and (min-width: $screen-md) {
			font-size: $font-size-20;
		}

		@media screen and (min-width: $screen-xl) {
			font-size: $font-size-24;
		}
	}
}


.u-mq-fs32 {
 
		font-size: $font-size-20;
		line-height: 130%;

		@media screen and (min-width: $screen-md) {
			font-size: $font-size-24;
		}

		@media screen and (min-width: $screen-xl) {
			font-size: $font-size-32;
		}
}

.u-mq-fs40 {
	p {
		font-size: $font-size-20;
		line-height: 130%;

		@media screen and (min-width: $screen-md) {
			font-size: $font-size-32;
		}

		@media screen and (min-width: $screen-xl) {
			font-size: $font-size-40;
		}
	}
}



.u-fw300 {
	font-weight: 300;
}
.u-fw400 {
	font-weight: 400;
}
.u-fw500 {
	font-weight: 500;
}
.u-fw700 {
	font-weight: 700;
}
.u-fw900 {
	font-weight: 900;
}


.u-wsn {
	white-space: nowrap;
} 