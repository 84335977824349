.v-tabs {

  z-index: 50;

  &.open &-list {
    display: block;
  }

  &.open {
    i.icon-chevron-down {
      transform: rotate(-180deg);
    }
  }

  &.open > &-wrap {
    display: block;
  }

  &-wrap {
    display: none;
  }

  &-list {
    padding: 1.2rem 0;
  }

  &-btn, &-current {
    font-size: $font-size-12;
    line-height: $font-size-16;
    font-family: $font-oswald;
    font-weight: 600;
    padding: 1.2rem 1.6rem;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 0.12em;
  }

  &-btn {
    color: $cl-black;

    &:hover span{
      border-color:  $cl-blue-1;
    }

    &:hover i.icon-arrow-down {
    animation-duration: 1s;
    animation-name: bouncingDown;
    animation-iteration-count: infinite;
    }

    &.current {
      span {
        border-color:  $cl-blue-1;
      }
    }
    span {
      display: inline-block;
      padding-bottom: .5rem;
      border-bottom: 2px solid $cl-white;
    }
  }

  &-current  {
    border-bottom: .2rem solid $cl-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding-right: 3rem;

    i.icon-chevron-down {
      margin-right: -2rem;
    }
  }

  &.e--no-dropdown  & {
    overflow: visible;
    &-current { display: none; }
 
    &-list {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: visible;
    }

    &-btn {
      white-space: nowrap;
      display: inline-flex;
      padding: 0;

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  @media only screen and (min-width: $screen-md ) {
    overflow: visible;

    &-current { display: none; }

    &-list {
      display: flex !important;
      white-space: nowrap;

      &.e--ha-center {
        justify-content: center;
      }

      &.e--justify {
        justify-content: space-between;
      }
    }


    &-btn {
      white-space: nowrap;
      padding: 0;

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
}