.vacation {
  padding: 2rem 1.6rem;
  position: relative;
  border: .1rem solid $cl-gray-3;
  border-radius: .2rem;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 59.28%, #FFFFFF 76.96%);
  }
 
  &-top {
    margin-bottom: 2.4rem;

    &__title {
      font-size: $font-size-18;
      line-height: $font-size-22;
      margin-bottom: 1rem;
      font-family: $font-oswald;
    }
  }

  &.opened:before {
    height: 0;
  }


  &.opened > &-body {
    height: auto;
    margin-bottom: 2rem;
  }

  &.opened > &-controls {
    margin-top: 0;
    &:after {
      opacity: 0;
    }
  }

  &-body {
    overflow: hidden;
    height: 20rem;
    transition: all 0.3s ease;

    &__location {
      i {
        margin-top: -0.2rem;
      }
    }

    p {
      font-size: $font-size-14;
      line-height: $font-size-18;
    }
  }

  &-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 10;

    .btn, .link {
      z-index: 10;
    }
  }

  @media screen and (min-width: $screen-md) {
    &-top {
      display: flex;
      justify-content: space-between;

      &__title {
        font-size: $font-size-24;
        line-height: $font-size-26;
        margin-bottom: 0;
      }
    }

    &-controls {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    &-body {
      p {
        font-size: $font-size-16;
        line-height: $font-size-22;
      }
    }
  }

  @media screen and (min-width: $screen-lg) {
    padding: 4rem 3.2rem;
  }
}