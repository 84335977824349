.tabs {

  border-bottom: .1rem solid $cl-gray-2;

  &.e--no-border {
    border-bottom: 0;
  }

  .tab {
    padding: 1.6rem 1.2rem;
    font-family: $font-oswald;
    font-weight: 500;
    font-size: $font-size-14;
    color: $cl-gray-2;
    display: inline-flex;

    &.active {
      color: $cl-blue-1;
    }
  }

  @media screen and (min-width: $screen-md) {
    .tab {
      font-size: $font-size-16;
    }
  }

  @media screen and (min-width: $screen-xl) {
    .tab {
      font-size: $font-size-18;
    }
  }
}