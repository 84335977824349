

.autocomplete {
  &[data-expanded='true']  .autocomplete-result-overflow { visibility: visible; }

  &[data-expanded='true']:after {
    transform: rotate(180deg);
  }

  &[data-expanded='true'] .autocomplete-input {
    border-bottom: 0;
    border-radius: 2rem 2rem 0 0;
  }

  &:after {
    content: "\e903";
    font-family: 'icomoon';
    font-size: 2rem;
    color:  $cl-blue-1;
    line-height: 4rem;
    position: absolute;
    right: 2rem;
    top: 0;
    z-index: -1;
  }

  &-input {
    width: 100%;
    height: 4.4rem;
    border: .2rem solid  $cl-blue-1;
    background: transparent;
    border-radius: 2rem;
    color: $cl-white;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    padding-left: 2rem;
    font-size: $font-size-16;
  }

  &-result-overflow {
    visibility: hidden;
    z-index: 50;
    max-height: 17rem;
    width: 100%;
    position: absolute;
    background: $cl-black;
    border-radius: 0 0 2rem 2rem;
    border: .2rem solid  $cl-blue-1;
    border-top: 0;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
  }

  &-result-list {
    position: relative !important;
    top: 0 !important;
    overflow-y: auto;
    max-height: 16rem;


    li {
      padding: 0.8rem 2rem;
      font-size: $font-size-16;
      cursor: pointer;

      &:hover {
        background: rgba(255,255,255, 0.05);
      }
    }
  }
}