.u-dp-flex {
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	&.e--mobile-nowrap {
		flex-wrap: nowrap;
	}

	&.e--nowrap {
		flex-wrap: nowrap;
	}

	&.e--ai-center {
		align-items: center;
	}

	&.e--justify {
		justify-content: space-between;
	}

	&.e--right {
		justify-content: right;
	}

	&.e--ai-end {
		align-items: flex-end;
	}

	&.e--ha-center {
		justify-content: center;
	}

	&.e--row-reverse {
		flex-direction: row-reverse;
	}

	&.e--column {
		flex-direction: column;
	}

	&.e--gap24 {
		margin: 0 -0.8rem;

		& > div {
			padding: 0.8rem;
		}
	}


	&.e--gap4 {
		margin: 0 -0.2rem;

		& > div {
			padding: 0.2rem;
		}
	}

	@media screen and (min-width: $screen-md) {
		&.e--gap24 {
			margin: 0 -1rem;

			& > div {
				padding: 1rem;
			}
		}
	}

	@media screen and (min-width: $screen-xl) {
		&.e--mobile-nowrap {
			flex-wrap: wrap;
		}

		&.e--gap24 {
			margin: 0 -1.2rem;
	
			& > div {
				padding: 1.2rem;
			}
		}

	}
}



// $flex-props-map: (
//   row: (
//     flex-direction: row
//   ),
//   reverse: (
//     flex-direction: row-reverse
//   ),
//   col-reverse: (
//     flex-direction: column-reverse
//   ),
//   ha-center: (
//     align-items: center
//   ),
//   ha-bottom: (
//     align-items: end
//   ),
//   va-center: (
//     justify-content: center
//   ),
//   va-right: (
//     justify-content: flex-end
//   ),
//   va-left: (
//     justify-content: flex-start
//   ),
//   nowrap: (
//     flex-wrap: nowrap
//   )
// );


// @mixin mix-flex-props($m-flex-props, $m-breakpoint: null) {
//   @each $name-props, $value-props in $m-flex-props {
//     @each $name-in-value-props, $value-in-value-props in $value-props {
//       $rule: "u-dp-flex-#{$name-props}";

//       #{$rule} {#{$name-in-value-props}: $value-in-value-props}
//     }

//     @if $m-breakpoint {
//       @each $name-mq, $value-mq in $m-breakpoint {
//         $rule-mq: #{$name-mq}\:;

//         @media (min-width: $value-mq) {
//           @each $name-in-value-props, $value-in-value-props in $value-props {
//             $rule: ".#{$rule-mq}u-dp-flex-#{$name-props}";

//             #{$rule} {#{$name-in-value-props}: $value-in-value-props}
//           }
//         }
//       }
//     }
//   }
// }

// @include mix-flex-props($flex-props-map, $media-map);