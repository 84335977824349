.btn {
  display: inline-flex;
  justify-content: center;
  font-size: $font-size-16;
  line-height: $font-size-16;
  letter-spacing: 0.14em;
  font-family: $font-oswald;
  text-transform: uppercase;
  border: .1rem solid;
  padding: 1.3rem 4.6rem;
  border-radius: .4rem;
  cursor: pointer;

  &:disabled {
    border-color: $cl-gray-3;
    color: $cl-gray-3;
    cursor: default;
  }

  &.e--df {
    background-color: $cl-blue-2;
    color: $cl-white;

    &:hover {
      border-color: $cl-blue-1;
      color: $cl-blue-1;
      background-color: $cl-white;
    }
  }

  &.e--iv {
    color: $cl-blue-2;

    &:hover {
      background-color: #64BDE1;
    }
  }
}

.btn-small {
  display: inline-flex;
  padding: .5rem 1.4rem;
  border-radius: 1rem;
  white-space: nowrap;
  background-color: $cl-gray-4;
  color: $cl-gray-1;
  font-size: 1.2rem;


  &.active {
    background: $cl-blue-2;
    color: $cl-white;
  }

}


.btn-rounded {
  border-radius: 50%;
  width: 21rem;
  height: 21rem;
  display: inline-flex;
  background:  $cl-blue-2;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  position: relative;
  background-clip: border-box;
  cursor: pointer;
  border: 0;

  &:hover {
    border: .1rem solid $cl-blue-1;
    background-color: $cl-white;

    span {
      color: $cl-blue-1;
    }
  }

  span {
    color: $cl-white;
    font-family: $font-oswald;
    font-size: $font-size-14;
    line-height: $font-size-20;
    letter-spacing: .2rem;
    z-index: 10;
  }

  &:disabled, .disabled {
    background: $cl-gray-3 !important;
    border-color: $cl-gray-3 !important;
    color: $cl-gray-3 !important;
    cursor: default;
  }

  &:disabled:before {
    opacity: 0 !important;
  }

  &.e--iv {
    background: $cl-white;

    span {
      color: $cl-blue-1;
    }
  }

  @media screen and (min-width: $screen-lg) {
    &.show {
      visibility: visible;
      z-index: 50;
      animation-duration: 1s;
      animation-name: bounceInDown;

      &:hover {
        opacity: 1;
      }
    }
  }
}


.btn-map-route {
  z-index: 10;
  background: $cl-white;
  box-shadow: 0px 0px 10px rgba(174, 174, 174, 0.25);
  border-radius: .4rem;   
  display: inline-flex;
  align-items: center;
}