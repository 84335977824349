.stuff {
  &-item {
    width: 29rem;
    height: 48rem; 

    &__image {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        object-fit: cover;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    &-item {
      width: 32rem;
      height: 53rem;
    }
  }

  @media screen and (min-width: $screen-xl) {
    &-item {
      width: 53rem;
      height: 53rem;

      &:hover img {
        filter: grayscale(0);
      }

      &__image {
        img {
          transition: all 1s ease;
          filter: grayscale(100%);
        }
      }
    }
  }

}