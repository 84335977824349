.a-img {
  display: block;
  width: 100%;
  height: auto;


  &-masked {
    width: 100%;
    padding-top: 80%;
    background:url('/assets/img/common/structure-image.png') 0 0 no-repeat;
    background-size: cover;
   // -webkit-mask-image: url('/assets/img/svg/tk.svg');
    mask-image: url('/assets/img/svg/tk.svg');
    mask-size: 100%;
  }
}


