.link {
  font-family: $font-merri;
  display: inline-flex;
  align-items: center;
  text-decoration: none;

  span {
    display: inline-block;
    padding-bottom: .2rem;
    border-bottom: .1rem solid;
    border-color: inherit;
    white-space: nowrap;
    line-height: 1;
  }

  i {
    transition: all 0.3s ease;
  }

  &:hover i.icon-arrow-to-go {
    transform: rotate(45deg);
  }

  &.e--tdu {
    text-decoration: underline;
  }

  &.e--dashed {
    span {
      border-bottom: .1rem dashed;
    }

    &:hover {
       color: $cl-blue-3;
       border-color: $cl-blue-3;
    }
  }
}