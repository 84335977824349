.search {
  &-field {
    position: relative;
    max-width: 78rem;

    &__input {
      width: 100%;
      text-align: center;
      color: $cl-gray-1;
      font-size: $font-size-14;
      line-height: $font-size-20;
      background: $cl-white;
      border: solid $cl-gray-2;
      border-width: 0 0 .1rem;
      padding-bottom: .7rem;
      margin-bottom: 2rem;
    }
  }

  &-results {
    &__item {
      color: $cl-gray-2;
      margin-bottom: .8rem;
    }
  }

  &-submit {
    right: 0;
    top: 0;
    background: transparent;
    border: 0;
    height: 3rem;
    width: 3rem;
    font-size: $font-size-20;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $cl-gray-1;
    cursor: pointer;

    &:hover {
      color: $cl-blue-3;
    }
  }
}


.search-popup {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: $cl-white;
  padding: 5.2rem 1.6rem;
  opacity: 0;
  transition: all 0.3s ease;

  &.is-visible {
    visibility: visible;
    opacity: 1;
  }

  .search-close {
    position: absolute;
    left: 50%;
    top: 1.6rem;
    transform: translateX(-50%);
    cursor: pointer;
  }

  @media screen and (min-width: $screen-lg) {
    padding: 10rem 1.6rem;

    .search-close {
      top: 2.8rem;
    }
  }
}


.search-vacations {
  &__item {

    &:hover a {
      color: $cl-blue-3;
    }

    &:not(:first-child) {
      border-top: 1px solid $cl-gray-2;
    }
  }
}