.lang {
   position: relative;
   font-size: 1.4rem;;
   font-family: $font-oswald;
   font-weight: 400;
   padding-left: 1.5rem;
   cursor: pointer;
   margin-left: .8rem;

   &.active {
      .lang-active {
         color: $cl-black;
      }
   }

   &-active {
      display: flex;
      line-height: 2.4rem;
      position: relative;
      z-index: 10;

      i {
         margin-left: .8rem;
      }
   }

   &.active > &-dropdown {
      visibility: visible;
      opacity: 1;
   }

   &-dropdown {
      position: absolute;
      left: 0;
      top: -1.5rem;
      background: $cl-white;
      width: 7.5rem;
      padding: 3.5rem 1.5rem .5rem;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;

      a {
         display: block;
         margin: 1rem 0;
         color: $cl-gray-2;
         text-decoration: underline;

         &:hover {
            color:  $cl-blue-1;
         }
      }
   }
}