.content {

  color: $cl-gray-1;


  h1, h2, h3, h4 {
    color: $cl-black;
  }

  p {
    font-size: $font-size-14;
    line-height: $font-size-20;
    margin-bottom: 1.6rem;
  }

  b, strong, blockquote {
    color: $cl-black;
    font-family: $font-merri;
    font-weight: 700;
  }

  blockquote {
    position: relative;
    font-size: $font-size-16;
    line-height: $font-size-24;
    font-family: $font-merri;
    font-weight: 900;
    font-style: italic;
    margin-bottom: 3rem;

    &:before {
      content: '';
      background: url('/assets/img/svg/quote.svg') 0 0 no-repeat;
      width: 4rem;
      height: 4rem;
      display: block;
      margin-bottom: 1rem;
    }
  }

  i {
    font-family: $font-merri;
    font-style: italic;
  }

  a {
    color: $cl-blue-3;
    font-style: italic;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    li {
      position: relative;
      margin-bottom: 1.4rem;
      padding-left: 2.2rem;
      font-size: $font-size-14;
      line-height: 140%;

      &:before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: inherit;
        display: block;
        position: absolute;
        left: 1rem;
        top: 0.8rem;
        background: $cl-black;
      }

      @media screen and (min-width: $screen-lg ) {
        font-size: $font-size-16;
      }
    }
  }

  ol {
    li {
      position: relative;
      margin-left: 1.6rem;
      margin-bottom: 1.4rem;
      font-size: $font-size-14;
      line-height: 2rem;
    }
  }

  h2 {
    font-size: $font-size-24;
    margin-bottom: 1.2rem;
  }

  h3 {
    font-size: $font-size-18;
    margin-bottom: 1.2rem;
  }

  h4 {
    font-size: $font-size-18;
    margin-bottom: 1.2rem;
  }

  @media screen and (min-width: $screen-md) {
    h2, h3, h4 {
      margin-bottom: 2.4rem;
    }
  }
}


.post-img {
  position: relative;

  &:before, &:after {
    content: '';
    width: 0.4rem;
    background: $cl-white;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
  }

  @media screen and (min-width: $screen-md) {
    &:before {
      left: calc(100% / 3);
    }

    &:after {
      left: calc((100% / 3) * 2);
    }
  }
}



.post-content {

  p {
    font-size: $font-size-14;
    line-height: $font-size-20;
    margin-bottom: 1.6rem;
  }


  img {
    margin-bottom: 1.6rem;
  }

  h1, h2, h3, h4 {
    line-height: 130%;
  }

  h2, h3, h4 {
    max-width: 105rem;
    margin-left: auto;
    margin-right: auto;
  }

  p, ul, ol, blockquote {
    max-width: 85rem;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: $font-size-20;
    margin-bottom: 1.2rem;
  }

  h2 {
    font-size: $font-size-18;
    margin-top: 3rem;
    margin-bottom: 1.2rem;
  }

  h3 {
    font-size: $font-size-14;
    margin-top: 3rem;
    margin-bottom: 1.2rem;
  }

  h4 {
    font-size: $font-size-14;
    margin-top: 3rem;
    margin-bottom: 1.2rem;
  }

  @media screen and (min-width: $screen-md) {

    p {
      font-size: $font-size-16;
      line-height: $font-size-24;
      margin-bottom: 2.4rem;
    }

    img {
      margin-bottom: 2.4rem;
    }

    h1 {
      font-size: $font-size-24;
      margin-bottom: 2.4rem;
    }
    h2 {
      font-size: $font-size-20;
      margin-top: 4rem;
      margin-bottom: 2.4rem;
    }
    h3 {
      font-size: $font-size-16;
      margin-top: 4rem;
      margin-bottom: 2.4rem;
    }
    h4 {
      font-size: $font-size-16;
      margin-top: 4rem;
      margin-bottom: 2.4rem;
    }
  }

  @media screen and (min-width: $screen-lg) {
    p, blockquote, ol li {
      font-size: $font-size-18;
      line-height: $font-size-26;
    }

    ul li {
      font-size: $font-size-18;
      line-height: $font-size-26;

      &:before {
        width: 0.35rem;
        height: 0.35rem;
        top: 1.2rem;
      }
    }

    h1 {
      font-size: $font-size-32;
      margin-bottom: 2.4rem;
    }
    h2 {
      font-size: $font-size-24;
      margin-top: 4rem;
      margin-bottom: 2.4rem;
    }
    h3 {
      font-size: $font-size-18;
      margin-top: 4rem;
      margin-bottom: 2.4rem;
    }
    h4 {
      font-size: $font-size-18;
      margin-top: 4rem;
      margin-bottom: 2.4rem;
    }
  }
}