.header {
  $root: &;
  margin: 1.3rem 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  color: $cl-black;

  #{$root}-navi__toggle {
    color: $cl-black;
  }

  &.e--inverse:not(.is-open) {
    #{$root}__logo {
       svg path {
        fill: $cl-white;
       }
    }

    #{$root}-navi__toggle {
      color: $cl-white;
    }

    .header-bar {
      color: $cl-white;
    }
  }

  #{$root}__logo {
    position: relative;
    z-index: 50;
    flex-shrink: 0;
    width: 3.2rem;
  }

  &.is-open {
    position: fixed;

    .l-container {
      position: relative;
      z-index: 100;
    }
  }

  #{$root}-navi {
    padding-top: 8rem;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99;
    background: $cl-white;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;

    &__toggle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.is-open {
      visibility: visible;
      opacity: 1;
   }

    a:hover, a.active {
      color: $cl-blue-1;
    }
  }

  @media screen and (min-width: $screen-md) {
    margin: 2.4rem 0;

    &__logo {
      width: 5.4rem;
    }

    &-navi {
      padding-top: 15rem;
    }
  }

  @media screen and (min-width: $screen-xl) {
    &__logo {
      width: 6.4rem;
    }

    &-navi {
      padding-top: 17rem;
    }
  }
}