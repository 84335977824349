$align-map: (
  center: center,
  center-auto: (
    text-align: center,
    margin-left: auto,
    margin-right: auto
  ),
  left: left,
  right: right,
  nowrap: (
    white-space: nowrap
  )
);

@mixin mix-align($m-align, $m-breakpoint: null) {
  @include mix-align-repeater($m-align);

  @if $m-breakpoint {
    @each $name-mq, $value-mq in $m-breakpoint {
      $rule-mq: #{$name-mq}\:;

      @media (min-width: $value-mq) {
        @include mix-align-repeater($m-align, $rule-mq);
      }
    }
  }
}

@mixin mix-align-repeater($m-align, $rule-mq:'') {
  @each $name-align, $value-align in $m-align {
    $rule: '.#{$rule-mq}u-ta-#{$name-align}';

    @if type-of($value-align) == string {
      #{$rule} {text-align: #{$value-align} !important}
    } @else if type-of($value-align) == map {
      #{$rule} {
        @each $name-align-map, $value-align-map in $value-align {
          #{$name-align-map}: #{$value-align-map} !important;
        }
      }
    }
  }
}

@include mix-align($align-map, $media-map);
