.alert {
  display: inline-flex;
  text-align: left;
  padding: 1rem 1.4rem;
  font-size: $font-size-12;
  line-height: $font-size-14;

  &.e--danger {
    color: $cl-error-color;
    background-color: $cl-error-bg;
  }
}