$flex-props-map: (
  row: (
    flex-direction: row
  ),
  reverse: (
    flex-direction: row-reverse
  ),
  col-reverse: (
    flex-direction: column-reverse
  ),
  justify: (
    justify-content: space-between
  ),
  ha-top: (
    align-items: flex-start
  ),
  ha-center: (
    align-items: center
  ),
  ha-bottom: (
    align-items: flex-end
  ),
  va-center: (
    justify-content: center
  ),
  va-right: (
    justify-content: flex-end
  ),
  va-left: (
    justify-content: flex-start
  ),
  nowrap: (
    flex-wrap: nowrap
  )
);


[class*="l-flex"] {
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 100%;
  }
}

@mixin mix-flex-spacing($m-size, $m-breakpoint: null) {
  @include mix-flex-spacing-repeater($m-size);

  @if $m-breakpoint {
    @each $n-breakpoint, $v-breakpoint in $m-breakpoint {
      $rule-breakpoint: \@#{$n-breakpoint};

      @media (min-width: $v-breakpoint) {
        @include mix-flex-spacing-repeater($m-size, $rule-breakpoint);
      }
    }
  }
}

@mixin mix-flex-props($m-flex-props, $m-breakpoint: null) {
  @each $name-props, $value-props in $m-flex-props {
    @each $name-in-value-props, $value-in-value-props in $value-props {
      $rule: ".lf-#{$name-props}";

      #{$rule} {#{$name-in-value-props}: $value-in-value-props}
    }

    @if $m-breakpoint {
      @each $name-mq, $value-mq in $m-breakpoint {
        $rule-mq: \@#{$name-mq};

        @media (min-width: $value-mq) {
          @each $name-in-value-props, $value-in-value-props in $value-props {
            $rule: ".lf-#{$name-props}#{$rule-mq}";

            #{$rule} {#{$name-in-value-props}: $value-in-value-props}
          }
        }
      }
    }
  }
}

@mixin mix-display-repeater($m-display, $rule-mq:'') {
  @each $value-display in $m-display {
    $rule: '.lf-#{$rule-mq}';

    #{$rule} {display: flex !important;}
  }
}

@include mix-display($display-map, $media-map);
@include mix-flex-props($flex-props-map, $media-map);
