//Sizing

$size-map: (
  a: auto,
  1r: 1rem,
  2r: 2rem,
  3r: 3rem,
  4r: 4rem,
  5r: 5rem,
  10r: 10rem,
  15r: 15rem,
  20r: 20rem,
  32r: 32rem,
  42r: 42rem,
  64r: 64rem,
  85r: 85rem,
  25p: 25%,
  33p: calc(100% / 3),
  40p: 40%,
  45p: 45%,
  50p: 50%,
  55p: 55%,
  60p: 60%,
  66p: calc((100% / 3) * 2),
  100p: 100%,
  100vh: 100vh,
);

$spacing-map: (
  '0': 0,
  '4': 0.4rem,
  '8': 0.8rem,
  '12': 1.2rem,
  '16': 1.6rem,
  '20': 2rem,
  '24': 2.4rem,
  '28': 2.8rem,
  '32': 3.2rem,
  '40': 4rem,
  '50': 5rem,
  '60': 6rem,
  '80': 8rem,
  '100': 10rem,
  'a': auto
);


//Mediaquery
// $min-screen-width: 320px;
$screen-sm: 480px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1280px;
$screen-xxl: 1600px;

$media-map: (
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl
);


/*
  $m-type - padding or margin.
  $m-direction - direction spacing.
  $m-size - size.
  $m-breakpoint - breakpoint.
  Example - '.u-pd-b5', '.u-pd-b5@lg'
*/

$spacing-directions: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left',
  'v': ('top', 'bottom'),
  'h': ('left', 'right')
);

$spacing-type: (
  p: padding,
  m: margin,
);

@mixin mix-spacing($m-type, $m-direction, $m-size, $m-breakpoint: null) {
  @include mix-spacing-repeater($m-type, $m-direction, $m-size, $rule-mq: '');
  @if $m-breakpoint {
    @each $name-mq, $value-mq in $m-breakpoint {
      $rule-mq: \@#{$name-mq};

      @media (min-width: $value-mq) {
        @include mix-spacing-repeater($m-type, $m-direction, $m-size, $rule-mq);
      }
    }
  }
}

@mixin mix-spacing-repeater($m-type, $m-direction, $m-size, $rule-mq: '') {
  @each $name-type, $value-type in $m-type {
    @each $name-size, $value-size in $m-size {
      @each $name-direction, $value-direction in $m-direction {
        $rule: '.u-#{$name-type}#{$name-direction}#{$name-size}#{$rule-mq}';

        @if type-of($value-direction) == string {
          #{$rule} {#{$value-type}-#{$value-direction}: $value-size !important}
        } @else if type-of($value-direction) == list {
          #{$rule} {
            @each $value-direction-list in $value-direction {
              #{$value-type}-#{$value-direction-list}: $value-size !important
            }
          }
        }
      }
    }
  }
}

@include mix-spacing($spacing-type, $spacing-directions, $spacing-map, $media-map);


$spacing-directions: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left',
  'v': ('top', 'bottom'),
  'h': ('left', 'right')
);

$spacing-mq-size: (
  '7': (
    'default': 2.5rem,
    'md': 4rem,
    'lg': 5rem,
    'xl': 7rem
  )
); 
@mixin mix-spacing-mq($m-type, $m-direction, $m-mq-size, $m-mq) {
  @each $name-type, $value-type in $m-type {
    @each $name-direction, $value-direction in $m-direction {
      @each $name-mq-size, $value-mq-size in $m-mq-size {
        @if type-of($value-mq-size) == map {
          @each $name-mq-in-size, $value-mq-in-size in $value-mq-size {
            @if map-has-key($m-mq, $name-mq-in-size) {
              @media (min-width: map-get($m-mq, $name-mq-in-size)) {
                @include mix-spacing-mq-repeater($name-type, $value-type, $name-direction, $value-direction, $name-mq-size, $value-mq-in-size);
              }
            }
            @if ($name-mq-in-size == 'default') {
              @include mix-spacing-mq-repeater($name-type, $value-type, $name-direction, $value-direction, $name-mq-size, $value-mq-in-size);
            }
          }
        }
      }
    }
  }
}
@mixin mix-spacing-mq-repeater($n-type, $v-type, $n-direction, $v-direction, $n-eq-size, $v-mq-in-size) {
  $rule: '.u-mq-#{$n-type}#{$n-direction}#{$n-eq-size}';
  @if type-of($v-direction) == string {
    #{$rule} {#{$v-type}-#{$v-direction}: #{$v-mq-in-size} !important}
  } @else if type-of($v-direction) == list {
    #{$rule} {
      @each $value-direction-list in $v-direction {
        #{$v-type}-#{$value-direction-list}: #{$v-mq-in-size} !important
      }
    }
  }
}
@include mix-spacing-mq($spacing-type, $spacing-directions, $spacing-mq-size, $media-map);


// u-pos
$position-directions-map: (
  t: top,
  r: right,
  b: bottom,
  l: left
);

$position-map: (
  0: 0,
  1: 1rem,
);

$position-addition-map: (
  rel: relative,
  abs: absolute
);

@mixin mix-position($m-size, $m-direction, $m-additional, $m-breakpoint: null) {
  @include mix-position-repeater($m-size, $m-direction, $m-additional);

  @if $m-breakpoint {
    @each $n-breakpoint, $v-breakpoint in $m-breakpoint {
      $rule-mq: \@#{$n-breakpoint};

      @media (min-width: $v-breakpoint) {
        @include mix-position-repeater($m-size, $m-direction, $m-additional, $rule-mq);
      }
    }
  }
}

@mixin mix-position-repeater($m-size, $m-direction, $m-additional, $rule-mq:"") {
  @each $n-add, $v-add in $m-additional {
    $rule: ".u-ps-#{$n-add}#{$rule-mq}";
    #{$rule} {position: $v-add}
  }
  @each $n-dir, $v-dir in $m-direction {
    @each $n-size, $v-size in $m-size {
      $rule: ".u-ps#{$n-dir}#{$n-size}#{$rule-mq}";

      #{$rule} {#{$v-dir}: $v-size}

      @if $n-size != 0 {
        $rule: ".u-ps#{$n-dir}n#{$n-size}#{$rule-mq}";

        #{$rule} {#{$v-dir}: -$v-size}
      }
    }
  }
}

@include mix-position($position-map, $position-directions-map, $position-addition-map, $media-map);


$display-map: (
  none,
  block,
  flex,
  inline-flex
);

@mixin mix-display($m-display, $m-breakpoint: null) {
  @include mix-display-repeater($m-display);

  @if $m-breakpoint {
    @each $name-mq, $value-mq in $m-breakpoint {
      $rule-mq: \@#{$name-mq};

      @media (min-width: $value-mq) {
        @include mix-display-repeater($m-display, $rule-mq);
      }
    }
  }
}

@mixin mix-display-repeater($m-display, $rule-mq:'') {
  @each $value-display in $m-display {
    $rule: '.u-dp-#{$value-display}#{$rule-mq}';

    #{$rule} {display: $value-display !important}
  }
}

@include mix-display($display-map, $media-map);


/*
  $m-type - width, height, min-width, max-width.
  $m-size - size.
  $m-breakpoint - breakpoint.
  Example - '.u-wd5', '.u-wd5@lg'
*/
$width-type-map: (
  wd: width,
  ht: height,
  minht: min-height,
  minwd: min-width,
  maxwd: max-width
);

@mixin mix-width($m-type, $m-size, $m-breakpoint: null) {
  @include mix-width-repeater($m-type, $m-size);
  @if $m-breakpoint {
    @each $name-mq, $value-mq in $m-breakpoint {
      $rule-mq: \@#{$name-mq};

      @media (min-width: $value-mq) {
        @include mix-width-repeater($m-type, $m-size, $rule-mq);
      }
    }
  }
}

@mixin mix-width-repeater($m-type, $m-size, $rule-mq: '') {
  @each $name-type, $value-type in $m-type {
    @each $name-size, $value-size in $m-size {
      $rule: '.u-#{$name-type}#{$name-size}#{$rule-mq}';

      #{$rule} {#{$value-type}: $value-size !important}
    }
  }
}

@include mix-width($width-type-map, $size-map, $media-map);


// text align
$align-map: (
  center: center,
  auto: (
    text-align: center,
    margin-left: auto,
    margin-right: auto
  ),
  left: left,
  right: right
);

@mixin mix-align($m-align, $m-breakpoint: null) {
  @include mix-align-repeater($m-align);

  @if $m-breakpoint {
    @each $name-mq, $value-mq in $m-breakpoint {
      $rule-mq: \@#{$name-mq};

      @media (min-width: $value-mq) {
        @include mix-align-repeater($m-align, $rule-mq);
      }
    }
  }
}

@mixin mix-align-repeater($m-align, $rule-mq:'') {
  @each $name-align, $value-align in $m-align {
    $rule: '.u-ta-#{$name-align}#{$rule-mq}';

    @if type-of($value-align) == string {
      #{$rule} {text-align: #{$value-align} !important}
    } @else if type-of($value-align) == map {
      #{$rule} {
        @each $name-align-map, $value-align-map in $value-align {
          #{$name-align-map}: #{$value-align-map} !important;
        }
      }
    }
  }
}

@include mix-align($align-map, $media-map);




// font size
$font-size-map: (
  12: 12px,
  14: 14px,
  16: 16px,
  18: 18px,
  20: 20px,
  22: 22px,
  24: 24px,
  28: 28px,
  32: 32px,
  44: 44px,
  52: 52px,
);


@mixin mix-font-size($m-size, $m-breakpoint: null) {
    @include mix-font-size-repeater($m-size);

    @if $m-breakpoint {
      @each $n-breakpoint, $v-breakpoint in $m-breakpoint {
        $rule-breakpoint: \@#{$n-breakpoint};

        @media (min-width: $v-breakpoint) {
          @include mix-font-size-repeater($m-size, $rule-breakpoint);
        }
      }
    }
  }

  @mixin mix-font-size-repeater($m-size, $rule-mq:"") {
    @each $n-size, $v-size in $m-size {
      $rule: ".u-fs#{$n-size}#{$rule-mq}";

      #{$rule} {font-size: $v-size}
    }
  }

  @include mix-font-size($font-size-map, $media-map);


 /* line height */ 

  @mixin mix-line-height($m-size, $m-breakpoint: null) {
    @include mix-line-height-repeater($m-size);

    @if $m-breakpoint {
      @each $n-breakpoint, $v-breakpoint in $m-breakpoint {
        $rule-breakpoint: \@#{$n-breakpoint};

        @media (min-width: $v-breakpoint) {
          @include mix-line-height-repeater($m-size, $rule-breakpoint);
        }
      }
    }
  }

  @mixin mix-line-height-repeater($m-size, $rule-mq:"") {
    @each $n-size, $v-size in $m-size {
      $rule: ".u-lh#{$n-size}#{$rule-mq}";

      #{$rule} {line-height: $v-size}
    }
  }

  @include mix-line-height($font-size-map, $media-map);

