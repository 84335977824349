.page-404 {
  margin-top: 4.8rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
  flex: auto;

  img {
    width: 100%;
    max-width: 25rem;
  }

  p {
    font-size: $font-size-18;
    line-height: $font-size-24;
  }

  @media screen and (min-width: $screen-md) {
    margin-top: 8.6rem;
    padding-top: 9rem;
    padding-bottom: 9rem;
   
    img {
      max-width: 40rem;
    }
  }

  @media screen and (min-width: $screen-xl) {
    margin-top: 0;
    
    img {
      max-width: 50rem;
    }
  }
}