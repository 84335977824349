.form {
  &-field {
    display: block;
    position: relative;
    
    &:not(:last-child) {
      margin-bottom: .4rem;
    }

    &.focused {
      label {
        top: .5rem;
        font-size: 1rem;
      }
    }

    .error {
      border-color: $cl-error-color;
    }

    &__error {
      color: $cl-error-color;
      font-size: $font-size-14;
      line-height: $font-size-18;
      padding-left: 2rem;
      display: block;
    }

    label {
      font-size: $font-size-12;
      color: $cl-gray-1;
      position: absolute;
      left: 1.4rem;
      top: 1.6rem;
      transition: all 0.3s ease;

      small {
        font-style: italic;
        opacity: 0.6;
      }
    }

    &__input {
      width: 100%;
      height: 4.4rem;
      background: $cl-gray-4;
      border-radius: .4rem;
      color: $cl-black;
      padding-top: 1.4rem;
      padding-left: 1.4rem;
      font-size: $font-size-14;
      border: 0;
    }

    &__textarea {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      background: $cl-gray-4;
      border: 0;
      border-radius: .4rem;
      color: $cl-black;
      padding-top: 1.4rem;
      padding-left: 1.4rem;
      font-size: $font-size-12;
    }

    &__file {
      .input-file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      &-btn {
        display: block;
        font-size: $font-size-14;
        cursor: pointer;
        position: relative;
        padding-left: 2.2rem;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 1.8rem;
          color: $cl-gray-2;
        }

        span {
          color: $cl-gray-2;
          font-size: $font-size-12;
        }

        u {
          text-decoration: none;
          color: $cl-blue-2;
          font-size: $font-size-14;
          display: inline-block;
          margin-bottom: .4rem;
          border-bottom: .1rem dashed;
        }
      }
    }
  }
}