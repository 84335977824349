[type="checkbox"].a-checkbox {
  $el: '.a-checkbox';
  opacity: 0;
  position: absolute;
  z-index: 10;
  + #{$el}__label {
    display: flex;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    padding-left: 3rem;
    font-size: $font-size-14;
    padding-top: .2rem;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      border: 0.2rem solid  $cl-blue-1;
      background-color: $cl-black;
      transition: all 0.15s ease;
      cursor: pointer;
      border-radius: 0.2rem;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0.2rem;
      left: 0.6rem;
      width: 0.7rem;
      height: 1.2rem;
      border: solid  $cl-blue-1;
      border-width: 0 0.2rem 0.2rem 0;
      opacity: 0;
      transform: rotate(45deg) scale(0);
      transition: all 0.15s ease;
    }
  }
  &:checked {
    + #{$el}__label {
      &:after {
        opacity: 1;
        transform: rotate(45deg) scale(1);
      }
    }
  }

  @media only screen and (min-width: $screen-md) {
    + #{$el}__label {
      font-size: $font-size-16;
    }
  }
}
 