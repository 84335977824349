.contacts {


  &-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }

  &-scroll {
    margin-right: -1.6rem;
    height: 36rem;
  }

  &__loc {
    width: 100%;
  }

  @media screen and (min-width: $screen-md) {
    &-row {
      align-items: flex-start;
    }
  }

  @media screen and (min-width: $screen-lg) {
    &-scroll {
      margin-right: 0;
      height: auto;
    }
    &-row {
      &__hours {
        margin-top: -3rem;
      }
    }

    &__loc {
       margin-right: 15rem;
    }

  }
}

.filials {
  &-item {
    &.active &__toggle {
      color: $cl-blue-1;
    }

    &__toggle {
      color: $cl-gray-3;
      cursor: pointer;
    }

    &.active {
      .contacts-row {
        display: flex;
      }
    }
  }

  &-content {
    margin-bottom: 2rem;
  }

  .contacts-row {
    display: none;
  }

 

  @media screen and (min-width: $screen-lg) {
    flex-direction: column;

    &-item {
      width: 40%;
    }
    
    &-content {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}