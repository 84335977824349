.l-main {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.l-section {
	width: 100%;
}

.l-container {
	padding-left: 1.6rem;
	padding-right: 1.6rem;
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;

	@media only screen and (min-width: $screen-md) {
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
}

.l-spacing-left {
	margin-left: 1.5rem;

	@media only screen and (min-width: $screen-md) {
		margin-left: 2.5rem;
	}

	@media only screen and (min-width: $screen-xl) {
		margin-left: auto;
		margin-right: auto;
		max-width: 1280px;
		padding-left: 2.4rem;
		padding-right: 2.4rem;
	}
}
