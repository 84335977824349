.o-accordion {

 
  .is-open .o-accordion-arrow {
    transform: rotate(180deg);
  }
    
  .o-accordion-body {
    display: none;
  }

  .is-open >  .o-accordion-body {
    max-height: 100rem;
  }

  &-mobile {
    border-bottom: .1rem solid $cl-black;
    &.last { border-bottom: 0; }

    &.is-open .o-accordion-arrow {
      transform: rotate(180deg);
    }

    .o-accordion-body {
      display: none;
    }

    &.is-open > .o-accordion-body {
      max-height: 100rem;
    }


    @media screen and ( min-width: $screen-md ) {
      border-bottom: 0;

      .o-accordion-arrow { display: none; }
      .o-accordion-body {
        display: block !important;
        max-height: 5rem;
      }
    }
  }
}
