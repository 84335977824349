.pagination {

  &-list {
    display: flex;
    justify-content: center;
    padding-top: 1.6rem;
  }

  &-item {
    font-family: $font-oswald;
    font-size: $font-size-18;
    line-height: $font-size-22;

    &__link {
      color: $cl-black;
      padding: 0 .8rem;
      text-align: center;
      display: block;
    }

    &__space {
      color: $cl-black;
      letter-spacing: .8rem;
      margin: 0 .7rem 0 1.4rem;
    }
  }
}