.b2b {
  height: 32rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;


  .breadcrumbs-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }


  &.e--bg:before {
    content: '';
    background: url("/assets/img/common/b2b-bg.png") 0 0 no-repeat;
    background-size: cover;
    width: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
  }

  &-container {
    z-index: 10;
    h1 {
      font-size: $font-size-40;
      margin-bottom: 1.6rem;
    }
  }

  @media screen and (min-width: $screen-md) {
    height: 44rem;
  }

  @media screen and (min-width: $screen-lg) {
    height: 51rem;
  }
}


.b2c {
  &-gal {
    position: relative;

    &-wrap {
      position: relative;

      &:after {
        content: '';
        width: 0;
        padding-top: 56%;
        display: block;
      }
    }

    &-item {
      display: block;
      position: absolute;
      right: 0;
      top: 0;

      &:nth-child(2) {
        transform: rotate(5deg);
      }

      &:nth-child(3) {
        transform: rotate(2deg);
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    &-btn {
      line-height: 10rem;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      background: $cl-white;
      color: $cl-blue-1;
      font-family: $font-oswald;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;

      &__wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
      }

      &:hover {
        background: $cl-blue-1;

        span {
          color: $cl-white;
        }
      }

      span {
        font-size: 12px;
        letter-spacing: 0.8px;
      }
    }
  }

  &-3d-btn {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}