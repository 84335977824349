.seo {
  h3 {
    font-size: $font-size-18;
    line-height: $font-size-24;
    margin-bottom: 2rem;
  }

  p {
    font-size: $font-size-14;
    line-height: $font-size-20;
    margin-bottom: 1.6rem;
    color: $cl-gray-1;
  }

  @media screen and (min-width: $screen-md) {
    h3 {
      font-size: $font-size-24;
      line-height: $font-size-26;
    }
    p {
      font-size: $font-size-18;
      line-height: 2.7rem;
    }
  }
}