.mfp {
  &-bg {
    opacity: 1;
    background: rgba(0,0,0, 0.7);
  }

  &-container {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  &-content {
    height: 100%;
  }
  @media only screen and (min-width: $screen-md) {
    &-content {
      height: auto;
    }
  }
}

.popup {
  padding: 5.6rem 1.6rem 2rem;
  background: $cl-white;
  height: 100%;
  position: relative;

  &.e--br30 {
    border-radius: 3rem;
  }

  &.e--bg {
    background: $cl-white url(/assets/img/svg/shedule-bg.svg) 103% 60% no-repeat;
  }

  &-close {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    cursor: pointer;

    &:hover i {
      color: $cl-blue-3;
    }
  }

  &-inner {
    max-width: 36rem;
    margin: 0 auto;
    height: fill-available;
  }

  &-header {
   margin-bottom: 2.4rem;

    &__title {
      font-size: $font-size-18;
      line-height: $font-size-24;
      text-transform: uppercase;
      font-family: $font-oswald;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1rem;
    }

    &__under {
      font-size: $font-size-14;
      line-height: $font-size-18;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    height: fill-available;
  }

  @media only screen and (min-width: $screen-md) {
    width: 55rem;
    height: 55rem;
    border-radius: 50%;
    padding: 0 11rem;
    margin: 0 auto;

    &.e--success {
      width: 42rem;
      height: 42rem;
      padding: 0 3rem;
    }

    &-close {
      top: 3rem;
      right: 50%;
      transform: translateX(50%);
    }
    
    &-form {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
    }

    &-inner {
      max-width: 100%;
      width: 100%;
      display: flex;
      align-items: center;
    }
    &-header {
      margin-bottom: 2.4rem;
   
       &__title {
         font-size: $font-size-24;
       }
    }
  }
}



.mfp-zoom-in {
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }

  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  &.mfp-removing {
    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}