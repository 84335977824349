.m-checkbox, .m-radio {
  display: flex;
  align-items: top;
  margin-bottom: 1.6rem;
  min-height: 2rem;

  &.e--ai-center {
    align-items: center;
  }

  small {
    display: block;
    font-size: $font-size-12;
    color: $cl-black;
  }
}


