.subdiv {
  .ss-img {
    overflow: hidden;

    .swiper-wrapper {
      transition-delay: 500ms;
    }

    &__slide {
    height: auto;
    }

    @media only screen and (min-width: $screen-md) {
      overflow: hidden;
      width: 46%;
      max-width: 42rem;
      margin-right: 2.4rem;
      margin-left: 0;
      z-index: 52;

      &__slide {
        width: 100%;
      }
    }
  }

  .ss-text {
    padding-bottom: 2rem;
    position: relative;

    &__slide {
      width: 100%;
      width: calc(100% - 3.2rem);
      padding: 0 1rem;
      align-items: center;
    }

    &__nav {
      display: none;
    }


    @media only screen and (min-width: $screen-md) {
      width: 52%;
      height: fit-content;
      overflow: visible;
      padding-bottom: 3.2rem;


      &:before {
        content: '';
        width: 1000%;
        height: 100%;
        position: absolute;
        background: $cl-white;
        z-index: 10;
        right: 100%;
      }

      &__nav {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 50;
      }

      &__arrow {
        cursor: pointer;
        padding: 0 1rem;

        &.swiper-button-disabled { color: #747474;  cursor: default;}

        i {
          font-size: 3rem;
        }
      }

      &__slide {
        width: 100%;
        display: flex;
        justify-content: center;

        &-inner {
          max-width: 49rem;
        }

        &-title {
          margin-top: 20%;
        }
      }
    }
  }

  @media only screen and (min-width: $screen-md) {
    display: flex;
    align-items: center;
  }
}


.factory {
  overflow: visible;

  &-slide {
    width: 90%;
  }

  @media only screen and (min-width: $screen-md) {
    &-slide {
     width: 53rem;
    }
  }
}