
.sp {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &-navi {
    width: 100%;
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.4rem;
    margin-bottom: 1rem;

    &__item {
       font-size: $font-size-18;
       line-height: $font-size-20;
       font-family: $font-oswald;
       font-weight: 300;
       text-align: center;
       z-index: 10;
       margin-top: 2.4rem;
       color: $cl-gray-1;

       &.active {
         color: $cl-blue-1;
       }

      span {
        cursor: pointer;
      }
    }
  }

  &-img-box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-img {
    position: absolute;

    &.e--big {
      width: 60%;
      position: relative;
      max-width: 32rem;
    }

    &.e--small {
      width: 40%;
      padding-left: 1.5rem;
      position: relative;
      max-width: 16rem;
    }
    &__item {
       visibility: hidden;
       height: 0;
      position: relative;
      bottom: 0;
      opacity: 0;
      transition: all 0.3s ease;

      &:before {
        content: '';
        display: block;
        width: 0;
        padding-top: 130%;
      }

      img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
      }

      &.active {
        visibility: visible;
        height: auto;
        opacity: 1;
        transition: all 0.3s ease 0.5s;
      }

      &.e--model {
        width: 40%;
        margin-left: auto;
        display: flex;
        flex-direction: column;

        &:before {
          display: none;
        }

        img {
          position: relative;
        }
      }

      &.e--brand {
        width: 55%;
        &:before {
          display: none;
        }
        img {
          position: relative;
        }
      }
    }
  }

  @media screen and (min-width: $screen-lg) {
    &-navi {
      &__item {
        font-size: $font-size-24;
         margin-top: 2.5rem;
         margin-bottom: 1rem;
      }
    }
    &-img {
      position: absolute;
  
      &.e--big {
        max-width: 40rem;
      }
  
      &.e--small {
        max-width: 23rem;
      }

      &__item {
        &.e--brand {
          width: 50%;
         }
      }
    }
  }
}


.sb {
  position: relative;
  display: flex;
  justify-content: space-between;

    &-navi {
      width: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &__item {
        font-size: $font-size-18;
        margin-top: 2.4rem;
        font-family: $font-oswald;
        font-weight: 300;
        cursor: pointer;
        white-space: nowrap;
        color: $cl-gray-1;

        &.active {
          color: $cl-blue-1;
        }
      }
    }

    &-bimg {
      width: 55%;
      margin-right: -5%;
      &__item {
        display: none;

        &.active {
          display: block;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

  &-simg {
    width: 45%;
    text-align: right;
    padding-left: 5%;
    margin-top: auto;
    flex-grow: 1;

    &__item {
      height: 100%;
      display: none;

      &.active {
        display: block;
      }

      &-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }
    img {
      width: 100%;
      margin-right: -20%;
      max-width: 30rem;
      align-self: end;
    }
  }

  @media screen and (min-width: $screen-md) {

    &-navi {
      &__item {
        font-size: $font-size-20;
        margin-top: 2.8rem;
      }
    }
    &-bimg {
      width: 50%;
      margin-right: 0;
      img {
        width: 100%;
      }
    }
    &-simg {
      margin-top: 0;
      img {
        max-height: 50rem;
        height: 100%;
        width: auto;
        margin-right: 0;
        max-width: 100%;
      }
    }
  }

  @media screen and (min-width: $screen-lg) {

    &-navi {
      &__item {
        font-size: $font-size-24;
        margin-top: 3.2rem;
      }
    }

    &-simg {
 
      img {
        max-height: 56rem;
      }
    }
 
  }
}