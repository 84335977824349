[type="radio"].a-radio {
  $el: '.a-radio';
  opacity: 0;
  position: absolute;
  width: 0;

  + #{$el}__label {
    position: relative;
    padding-left: 3rem;
    cursor: pointer;
    margin-bottom: 0;
    font-size: $font-size-14;
    padding-top: .2rem;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 2rem;
      height: 2rem;
      border: 0.2rem solid  $cl-blue-1;
      background-color: $cl-black;
      transition: all 0.15s ease;
      border-radius: 50%;
    }
    &::after {
      content: '';
      position: absolute;
      width: 1.2rem;
      height: 1.2rem;
      background-color:  $cl-blue-1;
      border-radius: 50%;
      left: 0.4rem;
      top: 0.4rem;
      opacity: 0;
      transition: all 0.15s ease;
    }
  }
  &:checked {
    + #{$el}__label {

      &:after {
        opacity: 1;
      }
    }
  }

  &:disabled {
    + #{$el}__label {
      opacity: 0.5;
      cursor: auto;
    }
  }

  @media screen and (min-width: $screen-md) {
  + #{$el}__label {
      font-size: $font-size-16;
      line-height: $font-size-18;
    }
  }
}
