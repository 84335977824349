.select {
  position: relative;

  &-styled {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: $cl-gray-4;
    border-radius: .4rem;
    width: 100%;
    font-size: 1.2rem;
    color: $cl-black;
    height: 4.4rem;
    padding-left: 1.4rem;
    position: relative;
    cursor: pointer;

    &:before {
      content: "\e903";
      font-family: 'icomoon';
      font-size: $font-size-20;
      color: black;
      position: absolute;
      right: 1rem;
      top: 1.2rem;
    }

    &.active:before {
      transform: rotate(180deg);
    }

    &.filled {
      span {
        font-size: 1rem;
      }
    }

    span {
      font-size: $font-size-12;
      color: $cl-gray-1;
      transition: all .3s ease;
    }

    p {
      font-size: $font-size-14;
      line-height: $font-size-20;
    }
  }

  &-hidden {
    display: none;
  }

  .select-options {
    position: absolute;
    width: 100%;
    top: 100%;
    margin-top: .2rem;
    z-index: 10;
    border-radius: .4rem;
    background: $cl-gray-4;
    padding: 1rem 1.4rem;
    box-shadow: 0px 4px 4px rgba(154, 154, 154, 0.25);

    li {
      font-size: $font-size-14;
      margin: 1.4rem 0;
      cursor: pointer;
    }
  }
}