.swiper {

  &-button-disabled {
    cursor: default;
     &:before {
       color: $cl-gray-3 !important;
     }
  }

  &-scrollbar {
    background: $cl-gray-3;

    &-drag {
      background: $cl-blue-1;
    }
  }
  &-horizontal  .swiper-scrollbar {
    margin: 1.2px 0;
    height: .2rem;
    left: 0;
    width: 100%;
    .swiper-scrollbar-drag {
      height: .4rem;
      top: -.1rem;
      border-radius: 0;
    }
  }

  

  &-vertical + .swiper-scrollbar {
    width: .4rem;
    .swiper-scrollbar-drag {
      width: .2rem;
      left: .1rem;
    }
  }

  &-pagination {
    bottom: 0 !important;

    &-bullet {
      width: 0.8rem;
      height: 0.8rem;
      margin: 0 1rem !important;
      opacity: 0.5;
      background:  $cl-blue-1;

      &-active {
        opacity: 1;
      }
    }
  }

  &-button-next {
    right: -1.6rem;
  } &-button-prev {
    left: -1.6rem;
  }

  &-button-next, &-button-prev {
    height: 4rem;
    width: 4rem;
    text-align: center;

    &:after {
      color: $cl-white;
      font-size: $font-size-18;
      line-height: 4rem;
    }
  }
}

.h-swiper {
  cursor: grab;
  padding-bottom: 1.6rem;
  margin-right: 1.6rem;

  .swiper-scrollbar {
      left: 0;
      bottom: 0;
    //  width: calc(100% - 1.6rem);

      &-drag {
        height: .3rem;
        top: .1rem;
      }
    }

  @media screen and (min-width: $screen-md) {
    padding-bottom: 2.4rem;
    margin-right: 2.4rem;

 
  }

  @media screen and (min-width: $screen-xl) {

    margin-right: 0;

    .swiper-scrollbar {
      width: 100%;
      max-width: 1280px;
    }
  }
}